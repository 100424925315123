export default [
    {
        path: '/administration/search',
        name: 'search-page',
        component: () => import('@/views/amic-views/components/settings/Settings.vue'),
        meta: {
            pageTitle: 'Search',
        },
    },
    {
        path: '/administration/reports-users',
        name: 'reports-users',
        component: () => import('@/views/amic-views/components/reports/ReportedUsers.vue'),
        meta: {
            pageTitle: 'Administration',
            breadcrumb: [
              {
                text: 'Reports',
              },
              {
                text: 'Reported users',
                active: true,
              },
            ],
          },
    },
    {
        path: '/administration/reports-recipes',
        name: 'reports-recipes',
        component: () => import('@/views/amic-views/components/settings/Settings.vue'),
        meta: {
            pageTitle: 'Administration',
            breadcrumb: [
              {
                text: 'Reports',
              },
              {
                text: 'Reported recipes',
                active: true,
              },
            ],
          },
    },
    {
        path: '/administration/reports-comments',
        name: 'reports-comments',
        component: () => import('@/views/amic-views/components/reports/ReportedComments.vue'),
        meta: {
            pageTitle: 'Administration',
            breadcrumb: [
              {
                text: 'Reports',
              },
              {
                text: 'Reported comments',
                active: true,
              },
            ],
          },
    },
    {
        path: '/administration/reports-chats',
        name: 'reports-chats',
        component: () => import('@/views/amic-views/components/settings/Settings.vue'),
        meta: {
            pageTitle: 'Administration',
            breadcrumb: [
              {
                text: 'Reports',
              },
              {
                text: 'Reported chats',
                active: true,
              },
            ],
          },
    },
    {
        path: '/administration/crashes',
        name: 'crashes-page',
        component: () => import('@/views/amic-views/components/settings/Settings.vue'),
        meta: {
            pageTitle: 'Administration',
            breadcrumb: [
              {
                text: 'Crashes',
                active: true,
              },
            ],
          },
    },
    {
        path: '/administration/contacts',
        name: 'contacts-page',
        component: () => import('@/views/amic-views/components/settings/Settings.vue'),
        meta: {
            pageTitle: 'Administration',
            breadcrumb: [
              {
                text: 'Contacts',
                active: true,
              },
            ],
          },
    },
    {
        path: '/administration/pushnotifications',
        name: 'push-notification-page',
        component: () => import('@/views/amic-views/components/settings/Settings.vue'),
        meta: {
            pageTitle: 'Administration',
            breadcrumb: [
              {
                text: 'Push notifications',
                active: true,
              },
            ],
          },
    },
]

