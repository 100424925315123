export default [
    {
        path: '/settings/settings',
        name: 'settings-page',
        component: () => import('@/views/amic-views/components/settings/Settings.vue'),
    },
    {
        path: '/settings/logout',
        name: 'logout',
        component: () => import('@/views/amic-views/components/Logout.vue'),
    },
]
